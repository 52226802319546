@import url("https://use.typekit.net/hpc5kir.css");

/* https://res.cloudinary.com/dgps1radv/image/upload/c_fill,w_200/mto5squ8abpmd02zymev */
$red: #ce3530;
$black: rgb(25, 25, 25);
$borderWidth: 4px;
$paddingY: 0.35em;
$sigFont: "trade-gothic-next", sans-serif;
// $subMenuIcon: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_scale,w_200/v1546914175/las-casitas-house_qciusd.png);
.latarvana {
  .ordering-menu {
    background: #f8eadb;
    /* background: none; */
    // color: white;

  }

  img.nav-bar-logo {
    display: block;
    // padding-left: 0.8em;
  }
  .cart.minimized {
    background-color: #000;


    padding-bottom: 0.35em;
    padding-left: 0.5em;
    border: none;
    /* background: black; */
    border-bottom: 2px solid rgba(255,255,255,0.8);
    /* background: repeating-linear-gradient(
                  90deg,
                  #dd0016,
                  #dd0016 40px,
                  #eee 40px,
                  #eee 80px
                ); */
  }

  .landing-page, .top-banner {
    font-family: $sigFont;
    /* color: white; */
    /* text-shadow: 0px 0px 4px rgba(255,255,255,1); */
    padding: 1em 0;
    margin: 0;
    display: block;
    min-height: 250px;
    /* max-height: 50vh; */
    // background: url(https://image.shutterstock.com/z/stock-photo-chinese-food-on-dark-wooden-table-noodles-fried-rice-dumplings-stir-fry-chicken-dim-sum-466836857.jpg);
    background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_fill,w_1200/qv0pycyffrv4mbaf47sw);
    background-size: cover;
    background-position: center 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    .small {
      font-size: 0.8em;
      font-style: italic;
    }
  }
  .landing-page .text {
    max-width: 720px;
    font-weight: 700;
    text-align: center;
    color: white;
    text-shadow: 1px 1px 1px #333;
    background: rgba(0,0,0,0.2);
    borader-radius: 0.1em;
    padding: 0.5em 0.8em;
    img {
      width: 90%;
      max-width: 700px;
      display: block;
      margin: 0 auto;
    }
  }

  a.checkout-button.button {
    background: $red;
    font-family: $sigFont;
    font-weight: 600;
    box-shadow: none;
    border: 2px solid #222;

  }

  /* .category__header {
    font-family: cursive;

  } */
  .category-name .text {

    // text-transform: uppercase;
    font-style: italic;
    font-family: $sigFont;
    font-weight: 700;
    font-size: 2.3em;
    color: $red;
    background: none;
    position: relative
  }
  // .category-name .text::before {
  //   content: " ";
  //   display: inline-block;
  //   background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_scale,w_200/v1546914175/las-casitas-house_qciusd.png) no-repeat;
  //   background-size: contain;
  //   // width: 28px;
  //   // height: 32px;
  //   // margin-right: -0.1em;
  //   width: 50px;
  //   height: 30px;
  //   // margin-right: -0.1em;
  //   position: absolute;
  //   left: -20px;
  //   top: 15px;
  // }

  .top-banner {
    // height: 30vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;
    color: white;
    text-shadow: 1px 1px 0 $red;
  }

  .pages-nav {
    font-size: 0.75em;
    font-family: $sigFont;
    font-weight: 700;
    text-transform: uppercase;
    background: #111;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 480px) {
      font-size: 4vw;
    }
  }

  a.page-link {
    text-decoration: none;
    color: white;
    display: block;
    padding: $paddingY 0.3em;
    padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
    // box-sizing: border-box;
    border-bottom: $borderWidth solid #111;
    &:hover, &.active {
      border-bottom: $borderWidth solid $red;
      color: $red;
    }
  }

  .full-menu-link-wrapper {
    padding: 1em 0;
    text-align: center;
    a.button {
      background: $red;
      color: white;
    }
  }

  .menu-item {
    .food-name {
      font-family: $sigFont;
      font-size: 1em;
      font-weight: 500;
      // color: $red;
    }
  }

  .add-to-cart-button button {
    font-family: $sigFont;
    border: 2px solid $red;
    background: white;
    color: $red;
  }
  a.order-online-link {
    margin-top: 0.5em;
    display: inline-block;
    text-decoration: none;
    color: white;
    padding: 0.4em 0.6em;
    border-radius: 0.2em;
    background: $red;
  }
}
